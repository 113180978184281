import { CARD_STYLES } from '../../../utils/constants/CSS/cardStyles'

const appsPageStyles = {
  card: {
    ...CARD_STYLES.card,
    maxWidth: 1100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cardTitle: CARD_STYLES.cardTitle,
  cardSubtitle: CARD_STYLES.cardSubtitle,
  youtubeImage: {
    maxWidth: '20%',
    minWidth: '120px',
    width: 'auto',
    height: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
  fbImage: {
    maxWidth: '10%',
    minWidth: '90px',
    width: 'auto',
    height: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
}

export default appsPageStyles
