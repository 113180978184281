import React from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'
import playerStatsTableStyles from './playerStatsTable.styles'
import statsHeaders from '../../utils/constants/statsHeaders'

const PlayerStatsTable = ({ playerData }) => {
  const useStyles = makeStyles(playerStatsTableStyles)
  const classes = useStyles()
  const translate = useTranslate()

  const headerCellsWithoutMin = Object.keys(statsHeaders).map((key) => (
    <TableCell key={key} className={classes.tableCell}>
      {statsHeaders[key]}
    </TableCell>
  ))

  const headerCellsWithoutMinGpGs = Object.keys(statsHeaders)
    .filter((key) => key !== 'gamesPlayed' && key !== 'gamesStarted')
    .map((key) => (
      <TableCell key={key} className={classes.tableCell}>
        {statsHeaders[key]}
      </TableCell>
    ))

  const playerObjects = {
    id: playerData.playerStats.playerId,
    fullName: playerData.playerStats.fullName,
    totalStats: [
      {
        gamesPlayed: playerData.playerStats.gamesPlayed,
        gamesStarted: playerData.playerStats.gamesStarted,
        minutes: playerData.playerStats.minutes,
        points: playerData.playerStats.gameStats.points,
        twoPointer: playerData.playerStats.gameStats.twoPointer,
        threePointer: playerData.playerStats.gameStats.threePointer,
        freeThrows: playerData.playerStats.gameStats.freeThrows,
        offensiveRebounds: playerData.playerStats.gameStats.offensiveRebounds,
        defensiveRebounds: playerData.playerStats.gameStats.defensiveRebounds,
        totalRebounds: playerData.playerStats.gameStats.totalRebounds,
        assists: playerData.playerStats.gameStats.assists,
        steals: playerData.playerStats.gameStats.steals,
        turnOvers: playerData.playerStats.gameStats.turnOvers,
        blocksAgainst: playerData.playerStats.gameStats.blocksAgainst,
        foulsCommited: playerData.playerStats.gameStats.foulsCommited,
        foulsDrawn: playerData.playerStats.gameStats.foulsDrawn,
        pir: playerData.playerStats.gameStats.pir,
      },
    ],

    avgStats: [
      {
        gamesPlayed: null,
        gamesStarted: playerData.playerStats.avgGamesStarted || '-',
        minutes: playerData.playerStats.avgMinutes || '-',
        points: playerData.playerStats.gameStats.avgPoints || '-',
        twoPointer: playerData.playerStats.gameStats.avgTwoPoints || '-',
        threePointer: playerData.playerStats.gameStats.avgThreePoints || '-',
        freeThrows: playerData.playerStats.gameStats.avgFreeThrows || '-',
        offensiveRebounds: playerData.playerStats.gameStats.avgOffensiveRebounds || '-',
        defensiveRebounds: playerData.playerStats.gameStats.avgDefensiveRebounds || '-',
        totalRebounds: playerData.playerStats.gameStats.avgRebounds || '-',
        assists: playerData.playerStats.gameStats.avgAssists || '-',
        steals: playerData.playerStats.gameStats.avgSteals || '-',
        turnOvers: playerData.playerStats.gameStats.avgTurnOvers || '-',
        blocksAgainst: playerData.playerStats.gameStats.avgBlocksAgainst || '-',
        foulsCommited: playerData.playerStats.gameStats.avgFoulsCommited || '-',
        foulsDrawn: playerData.playerStats.gameStats.avgFoulsDrawn || '-',
        pir: playerData.playerStats.gameStats.avgPIR || '-',
      },
    ],
  }

  const mapPlayerObjectsToFormat = (playerData) => {
    const id = playerData.playerStats.playerId
    const fullName = playerData.playerStats.fullName
    const stats = playerData.teamPlayerStatsPerGame
      .filter((game) => game.opponentName !== null)
      .map((game) => {
        return {
          Game: `vs ${game.opponentName}`,
          MIN: `${game.minutes}`,
          Pts: game.gameStats.points,
          FG2: game.gameStats.twoPointerGameRatio,
          FG3: game.gameStats.threePointerGameRatio,
          FT: game.gameStats.freeThrowsGameRatio,
          OR: game.gameStats.offensiveRebounds,
          DR: game.gameStats.defensiveRebounds,
          TR: game.gameStats.totalRebounds,
          As: game.gameStats.assists,
          St: game.gameStats.steals,
          To: game.gameStats.turnOvers,
          BLKA: game.gameStats.blocksAgainst,
          FC: game.gameStats.foulsCommited,
          FD: game.gameStats.foulsDrawn,
          PIR: game.gameStats.pir,
        }
      })

    return {
      id: id,
      fullName: fullName,
      stats: stats,
    }
  }

  const mappedPlayerData = mapPlayerObjectsToFormat(playerData)

  const renderTableRows = (stats, title) => {
    return stats.map((row, index) => (
      <TableRow key={index}>
        {title && (
          <TableCell className={classes.tableCell}>
            <Typography className={classes.typo}>{title}</Typography>
          </TableCell>
        )}

        {Object.entries(row).map(([key, value]) => (
          <TableCell key={key} className={classes.tableCell}>
            {value}
          </TableCell>
        ))}
      </TableRow>
    ))
  }

  const validStats = mappedPlayerData.stats.filter((game) => game.MIN !== '00:00')
  const didNotPlayStats = mappedPlayerData.stats.filter(
    (game) => game.MIN === '00:00' || game.MIN === null,
  )

  const shouldShowGameStatsTable = mappedPlayerData.stats.length > 0
  return (
    <div>
      <Typography variant='h6' gutterBottom className={classes.marginTypo}>
        {translate('ra.text.seasonStats')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell className={classes.tableCell} />
              {headerCellsWithoutMin}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows(playerObjects.totalStats, 'Total')}</TableBody>
          <TableBody>{renderTableRows(playerObjects.avgStats, 'Average')}</TableBody>
        </Table>
      </TableContainer>

      {shouldShowGameStatsTable && (
        <>
          <Typography variant='h6' gutterBottom className={classes.marginTypo}>
            {translate('ra.text.gameStats')}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classes.tableCell} />
                  {headerCellsWithoutMinGpGs}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableRows(validStats)}
                {didNotPlayStats.length > 0 &&
                  didNotPlayStats.map((game, index) => (
                    <TableRow key={index}>
                      <TableCell colSpan={1} className={classes.tableCell}>
                        {game.Game}
                      </TableCell>
                      <TableCell colSpan={15} className={classes.tableCell}>
                        {translate('ra.text.didNotPlay')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  )
}

PlayerStatsTable.propTypes = {
  playerData: PropTypes.shape({
    playerStats: PropTypes.shape({
      playerId: PropTypes.string,
      fullName: PropTypes.string,
      opponentName: PropTypes.string,
      gamesPlayed: PropTypes.number,
      gamesStarted: PropTypes.number,
      avgGamesStarted: PropTypes.number,
      minutes: PropTypes.string,
      avgMinutes: PropTypes.string,
      gameStats: PropTypes.shape({
        points: PropTypes.number,
        avgPoints: PropTypes.number,
        twoPointer: PropTypes.number,
        avgTwoPoints: PropTypes.number,
        totalTwoPointsAttempts: PropTypes.number,
        threePointer: PropTypes.number,
        avgThreePoints: PropTypes.number,
        totalThreePointsAttempts: PropTypes.number,
        freeThrows: PropTypes.number,
        avgFreeThrows: PropTypes.number,
        totalFreeThrowsAttempts: PropTypes.number,
        offensiveRebounds: PropTypes.number,
        avgOffensiveRebounds: PropTypes.number,
        defensiveRebounds: PropTypes.number,
        avgDefensiveRebounds: PropTypes.number,
        totalRebounds: PropTypes.number,
        avgRebounds: PropTypes.number,
        assists: PropTypes.number,
        avgAssists: PropTypes.number,
        steals: PropTypes.number,
        avgSteals: PropTypes.number,
        turnOvers: PropTypes.number,
        avgTurnOvers: PropTypes.number,
        blocksAgainst: PropTypes.number,
        avgBlocksAgainst: PropTypes.number,
        foulsCommited: PropTypes.number,
        avgFoulsCommited: PropTypes.number,
        foulsDrawn: PropTypes.number,
        avgFoulsDrawn: PropTypes.number,
        pir: PropTypes.number,
        avgPIR: PropTypes.number,
        twoPointerGameRatio: PropTypes.string,
        threePointerGameRatio: PropTypes.string,
        freeThrowGameRatio: PropTypes.string,
      }),
    }),
    teamPlayerStatsPerGame: PropTypes.arrayOf(
      PropTypes.shape({
        playerId: PropTypes.string,
        fullName: PropTypes.string,
        opponentName: PropTypes.string,
        gamesPlayed: PropTypes.number,
        gamesStarted: PropTypes.number,
        minutes: PropTypes.string,
        gameStats: PropTypes.shape({
          points: PropTypes.number,
          avgPoints: PropTypes.number,
          twoPointer: PropTypes.number,
          avgTwoPoints: PropTypes.number,
          totalTwoPointsAttempts: PropTypes.number,
          threePointer: PropTypes.number,
          avgThreePoints: PropTypes.number,
          totalThreePointsAttempts: PropTypes.number,
          freeThrows: PropTypes.number,
          avgFreeThrows: PropTypes.number,
          totalFreeThrowsAttempts: PropTypes.number,
          offensiveRebounds: PropTypes.number,
          avgOffensiveRebounds: PropTypes.number,
          defensiveRebounds: PropTypes.number,
          avgDefensiveRebounds: PropTypes.number,
          totalRebounds: PropTypes.number,
          avgRebounds: PropTypes.number,
          assists: PropTypes.number,
          avgAssists: PropTypes.number,
          steals: PropTypes.number,
          avgSteals: PropTypes.number,
          turnOvers: PropTypes.number,
          avgTurnOvers: PropTypes.number,
          blocksAgainst: PropTypes.number,
          avgBlocksAgainst: PropTypes.number,
          foulsCommited: PropTypes.number,
          avgFoulsCommited: PropTypes.number,
          foulsDrawn: PropTypes.number,
          avgFoulsDrawn: PropTypes.number,
          pir: PropTypes.number,
          avgPIR: PropTypes.number,
        }),
      }),
    ),
  }),
}

export default PlayerStatsTable
