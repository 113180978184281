const QueryKeys = {
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
  SIGN_UP: 'signUp',
  GET_PERSONAL_DETAILS: 'getPersonalDetails',
  GET_ACCOUNT_ME: 'getAccountMe',
  GET_PLAN: 'getPlan',
  PENDING_CONFIRMATION: 'pendingConfirmation',
  NEW_PENDING_CONFIRMATION: 'newPendingConfirmation',
  DELETE_PENDING_CONFIRMATION: 'deletePendingConfirmation',
  RESEND_PENDING_CONFIRMATION: 'resendPendingConfirmation',
  POST_PHONE_NUMBER: 'postPhoneNumber',
  CHANGE_PASSWORD: 'changePassword',
  DISABLE_ACCOUNT: 'disableAccount',
  PERSONAL_DETAILS: 'personalDetails',
  IDENTITY_EMAIL: 'identityEmail',
  PREFERENCES_KEYS: 'preferencesKeys',
  PREFERENCES_KEYS_GENERAL: 'preferencesKeysGeneral',
  PREFERENCES_KEYS_TEMPORAL: 'preferencesKeysTemporal',
  GET_PREFERENCES_GENERAL: 'getPreferencesGeneral',
  GET_PREFERENCES_TEMPORAL: 'getPreferencesTemporal',
  PUT_PREFERENCES_GENERAL: 'putPreferencesGeneral',
  PUT_PREFERENCES_TEMPORAL: 'putPreferencesTemporal',
  POST_FILE_UPLOAD: 'postFileUpload',
  PUT_PROFILE_PICTURE: 'putProfilePicture',
  ADD_ORG: 'addOrg',
  GET_ORGS: 'getOrgs',
  GET_SINGLE_ORG: 'getSingleOrg',
  GET_ORG_PENDING_INVITATIONS: 'getOrgPendingInvitations',
  GET_ORG_USERS: 'getOrgUsers',
  GET_ORG_ROLES: 'getOrgRoles',
  INVITE_USERS_TO_ORG: 'inviteUsersToOrg',
  DELETE_USER_FROM_ORG: 'deleteUserFromOrg',
  UPDATE_USER_ORG_ROLES: 'updateUserOrgRoles',
  PASS_ORG_OWNERSHIP: 'passOrgOwnership',
  GET_COUNTRIES: 'getCountries',
  GET_AGE_RESTRICTION: 'getAgeRestriction',
  GET_GENDER_SPEC: 'getGenderSpec',
  GET_TEAMS: 'getTeams',
  GET_SINGLE_TEAM: 'getSingleTeam',
  ADD_TEAM: 'addTeam',
  GET_EVENT_TYPES: 'getEventTypes',
  GET_EVENTS: 'getEvents',
  GET_EVENT: 'getEvent',
  GET_TACTICS: 'getTactics',
  GET_DRILLS: 'getDrills',
  GET_PLAYS: 'getPlays',
  GET_ORG_PLAYERS: 'getOrgPlayers',
  GET_DRILL: 'getDrill',
  GET_REVISION: 'getRevision',
  GET_LATEST_REVISION: 'getLatestRevision',
  GET_REVISIONS: 'getRevisions',
  GET_INTENSITIES: 'getIntensities',
  GET_POSITIONS: 'getPositions',
  GET_PRACTICES: 'getPractices',
  GET_PRACTICE_PLANS: 'getPracticePlans',
  GET_PRACTICE_PLAN: 'getPracticePlan',
  GET_PLAYBOOKS: 'getPlaybooks',
  GET_USER_PREFERENCES: 'getUserPreferences',
  GET_USER_EMAIL_PENDING_CONFIRMATIONS: 'getUserEmailPendingConfirmations',
  GET_USER_BY_EMAIL: 'getUserByEmail',
  UPDATE_USER_LOGO: 'updateUserLogo',
  UPDATE_USER_PERSONAL_SETTINGS: 'updateUserPersonalSettings',
  UPDATE_USER_EMAIL_ADDRESS: 'updateUserEmailAddress',
  UPDATE_USER_PHONE_NUMBER: 'updateUserPhoneNumber',
  GET_COURTS: 'getCourts',
  GET_COURT: 'getCourt',
  GET_LIVESTREAM: 'getLivestream',
  GET_RUNNING_BROADCASTS_EVENTS: 'getRunningBroadcastsEvents',
  GET_RECENT_BROADCASTS: 'getRecentBroadcasts',
  GET_ORGS_AFTER_LOGIN: 'getOrgsAfterLogin',
  GET_TEAMS_AFTER_LOGIN: 'getTeamsAfterLogin',
  INVITATION_ACCEPT: 'invitationAccept',
  INVITATION_OWNERSHIP_ACCEPT: 'invitationOwnershipAccept',
  INVITATION_REJECT: 'invitationReject',
  DELETE_INVITATION: 'deleteInvitation',
  UPDATE_INVITATION: 'updateInvitation',
  GET_INVITATIONS: 'getUserInvitations',
  GET_INVITATIONS_NOTIFICATION: 'getUserInvitationsNotification',
  GET_LIVESTREAM_TYPES: 'getLivestreamTypes',
  GET_SUBSCRIPTION_OPTIONS: 'getSubscriptionOptions',
  VIVA_WEBHOOK: 'vivaWebhook',
  GET_SUBSCRIPTION_DETAILS: 'getSubscriptionDetails',
  GET_PAYMENTS: 'getPayments',
  GET_DOWNLOAD_RECORDING_URL: 'getDownloadRecordingUrl',
  GOOGLE_AUTHORIZATION_CALLBACK: 'googleAuthorizationCallback',
  GET_PRIVACY_STATUS: 'getPrivacyStatus',
  GET_SPECIFIC_LIVESTREAM: 'getSpecificLivestream',
  GET_VIDEO_URL: 'getVideoUrl',
  GET_PRACTICE_PLAYERS: 'getPracticePlayers',
  GET_PLAYER: 'getPlayer',
  GET_TEAM_STATS: 'getTeamStats',
  GET_PLAYER_STATS: 'getPlayerStats',
  GET_GAME_STATS: 'getGameStats',
  GET_TEAM_GAMES_STATS: 'getTeamGamesStats',
  GET_META_CONFIGURATION: 'getMetaConfiguration',
}

export default QueryKeys
