import * as React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  EVENT_TYPE_DEFAULT_VALUE,
  EVENT_TYPE_FORMS,
  Switch,
} from '../../utils/constants/eventTypeForms'
import SelectEventTypeField from '../SelectEventTypeField/SelectEventTypeField'
import FormContainer from '../layout/FormContainer/FormContainer'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'
import ContainerPadding from '../containerPadding/containerPadding'
import PracticeEventForm from '../form/forms/TeamCalendarForms/PracticeEventForm'
import TripEventForm from '../form/forms/TeamCalendarForms/TripEventForm'
import OtherEventForm from '../form/forms/TeamCalendarForms/OtherEventForm'
import MeetingEventForm from '../form/forms/TeamCalendarForms/MeetingEventForm'
import GameEventForm from '../form/forms/TeamCalendarForms/GameEventForm'
import mutationType from '../../types/mutationType'
import eventVariantType from '../../types/events/eventVariantType'
import teamType from '../../types/teamType'
import courtType from '../../types/courtType'
import calendarDateClickType from '../../types/calendarDateClickType'
import eventRawType from '../../types/events/eventRawType'
import eventType from '../../types/events/eventType'
import selectEventTypeStyles from './selectEventType.styles'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import isUserScopesValid from '../../utils/helpers/isUserScopesValid'
import { USER_SCOPES } from '../../utils/constants/userScopes'

const SelectEventType = (props) => {
  const {
    selectedEventType,
    event,
    formDirty,
    setFormDirty,
    createEvent,
    updateEvent,
    eventTypes,
    setSelectedEventType,
    clickedEventId,
    currentTeam,
    teams,
    dateClick,
    eventsRaw,
    setEvents,
    disabled,
    courts,
  } = props
  const translate = useTranslate()
  const useStyles = makeStyles(selectEventTypeStyles)
  const classes = useStyles()

  // const showAnnouncementPostField =

  const formProps = {
    createEvent,
    updateEvent,
    clickedEventId,
    event,
    selectedEventType,
    setFormDirty,
    currentTeam,
    teams,
    dateClick,
    eventsRaw,
    setEvents,
    courts,
  }

  return (
    <ContainerPadding paddingValue='1%'>
      <FormContainer>
        <div className={classes.wrapper}>
          <SelectEventTypeField
            disabled={disabled}
            eventTypes={eventTypes}
            selectedEventType={selectedEventType ?? EVENT_TYPE_DEFAULT_VALUE}
            setSelectedEventType={setSelectedEventType}
          />
          <ButtonWithLoader
            label={event ? translate('ra.buttons.save') : translate('ra.buttons.create')}
            loading={createEvent.isLoading || updateEvent.isLoading}
            disabled={clickedEventId && !formDirty}
            form='my-form'
          />
        </div>
      </FormContainer>
      <Switch test={selectedEventType?.code ?? EVENT_TYPE_FORMS.GAME}>
        <PracticeEventForm value={EVENT_TYPE_FORMS.PRACTICE} {...formProps} />
        <OtherEventForm value={EVENT_TYPE_FORMS.OTHER} {...formProps} />
        <GameEventForm value={EVENT_TYPE_FORMS.GAME} {...formProps} />
        <TripEventForm value={EVENT_TYPE_FORMS.TRIP} {...formProps} />
        <MeetingEventForm value={EVENT_TYPE_FORMS.MEETING} {...formProps} />
      </Switch>
    </ContainerPadding>
  )
}

SelectEventType.propTypes = {
  formDirty: PropTypes.bool,
  clickedEventId: PropTypes.string,
  createEvent: mutationType,
  updateEvent: mutationType,
  eventTypes: PropTypes.arrayOf(eventVariantType),
  event: eventType,
  selectedEventType: eventVariantType,
  setSelectedEventType: PropTypes.func,
  setFormDirty: PropTypes.func,
  currentTeam: teamType,
  teams: PropTypes.arrayOf(teamType),
  dateClick: calendarDateClickType,
  eventsRaw: PropTypes.arrayOf(eventRawType),
  setEvents: PropTypes.func,
  disabled: PropTypes.bool,
  courts: PropTypes.arrayOf(courtType),
}

export default SelectEventType
