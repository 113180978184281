import localStorageIds from '../constants/localStorageIds'
import { USER_SCOPES } from '../constants/userScopes'
import localStorageHelper from './localStorageHelper'

const isOrgAddonsValid = (allowedScopes) => {
  const { ALL } = USER_SCOPES
  if (allowedScopes === ALL) return true

  const { localStorageValue: selectedOrg } = localStorageHelper(localStorageIds.SELECTED_ORG)
  const { addons } = selectedOrg || {}

  if (!addons) return false

  return addons.some((scope) => allowedScopes.includes(scope))
}

export default isOrgAddonsValid
