import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import GamesList from './GamesList'
import StatsNavBar from './StatsNavBar'

const StatsNavigationPage = ({ children }) => {
  const [currentTab, setCurrentTab] = useState('stats')
  const previousTab = useRef(currentTab)

  // Handle tab changes and update URL hash
  const handleTabChange = (tab) => {
    previousTab.current = currentTab
    setCurrentTab(tab)
    window.history.pushState({ tab }, '', `#${tab}`)
  }

  // Initialize tab state from URL hash or default to 'stats'
  useEffect(() => {
    const hash = window.location.hash.replace('#', '')
    const initialTab = hash || 'stats' // Default to 'stats' if no hash is present
    setCurrentTab(initialTab)
  }, [])

  // Sync tab state with URL hash on popstate events
  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state && event.state.tab) {
        setCurrentTab(event.state.tab)
      } else {
        setCurrentTab('stats')
      }
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  return (
    <div>
      <StatsNavBar currentTab={currentTab} setCurrentTab={handleTabChange} />
      {currentTab === 'games' ? <GamesList /> : children}
    </div>
  )
}

StatsNavigationPage.propTypes = {
  children: PropTypes.node,
}

export default StatsNavigationPage
