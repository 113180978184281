const formatFullName = (fullName) => {
  if (!fullName) return ''

  const names = fullName.split(' ')
  if (names.length === 1) return fullName

  const firstInitial = names[0].charAt(0).toUpperCase()
  const lastName = names.slice(1).join(' ')

  const formattedName = `${firstInitial}. ${lastName}`

  return formattedName
}

export default formatFullName
