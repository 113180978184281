import React from 'react'
import PropTypes from 'prop-types'
import isOrgAddonsValid from '../../utils/helpers/isOrgAddonsValid'

const IsOrgAddonsAuthorized = ({ acceptedAddons, children }) => {
  if (!children) return null
  const isAccepted = isOrgAddonsValid(acceptedAddons)

  return isAccepted ? <div>{children}</div> : null
}

IsOrgAddonsAuthorized.propTypes = {
  acceptedAddons: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default IsOrgAddonsAuthorized
