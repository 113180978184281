import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles, Card, CardContent, Typography, Avatar } from '@material-ui/core'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'
import { useTranslate } from 'react-admin'
import MyChip from '../../components/MyChip/MyChip'

const PlayerInfoCard = ({ playerInfo }) => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Card className={classes.teamCard}>
      <Avatar
        className={classes.teamLogo}
        src={playerInfo?.logoUrl || ''}
        alt={playerInfo?.personalData?.firstName || 'Player'}
      />
      <CardContent className={classes.teamCardContent}>
        <Typography variant='h5'>
          {`${playerInfo?.personalData?.firstName || '-'} ${playerInfo?.personalData?.lastName || '-'}`}
        </Typography>
        <Typography variant='subtitle1'>{playerInfo?.teams?.[0]?.name || '-'}</Typography>
        <Box className={classes.infoBox}>
          <Typography variant='body1'>
            {translate('ra.text.jerseyNumber')} {playerInfo?.teams?.[0]?.jerseyNumber || '-'}
          </Typography>
          <Typography variant='body1'>
            {translate('ra.text.height')} {playerInfo?.personalData?.height || '-'}
          </Typography>
          <Typography variant='body1'>
            {translate('ra.text.positions')}
            {playerInfo?.personalData?.positions && playerInfo.personalData.positions.length > 0
              ? playerInfo.personalData.positions?.map((position) => (
                  <MyChip key={position.id} label={position.name} small />
                ))
              : '-'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

// Define PropTypes for the component
PlayerInfoCard.propTypes = {
  playerInfo: PropTypes.shape({
    id: PropTypes.string,
    personalData: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      dateOfBirth: PropTypes.string,
      height: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      positions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
    }),
    clothingData: PropTypes.shape({
      shoeSize: PropTypes.string,
      jerseySize: PropTypes.string,
      shortsSize: PropTypes.string,
    }),
    logoUrl: PropTypes.string,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        team: PropTypes.string,
        name: PropTypes.string,
        jerseyNumber: PropTypes.number,
      }),
    ),
  }),
}

export default PlayerInfoCard
