import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'
import perQuarterTableStyles from './perQuarterTable.styles'

const PerQuarterTable = ({ teams, currentPeriod, sum }) => {
  const useStyles = makeStyles(perQuarterTableStyles)
  const translate = useTranslate()
  const classes = useStyles()

  const findUniqueMax = (array) => {
    const max = Math.max(...array)
    const count = array.filter((value) => value === max).length
    return count === 1 ? max : null
  }

  const periods = teams?.reduce((acc, team) => {
    Object.keys(team.periodScores).forEach((period) => {
      if (
        (!acc.includes(period) && period <= 4) ||
        (!acc.includes(period) && period > 4 && team.periodScores[period] != null)
      ) {
        acc.push(period)
      }
    })
    return acc
  }, [])

  let lastScoredPeriod = -1
  // Compute cumulative scores if sum is enabled
  const cumulativeScores = teams.map((team) => {
    periods.forEach((period) => {
      if (!currentPeriod && team.periodScores[period] !== null && period > lastScoredPeriod) {
        lastScoredPeriod = period
      } else {
        lastScoredPeriod = currentPeriod
      }
    })

    // Create the cumulative scores object
    const periodScores = periods.reduce((acc, period) => {
      if (period <= lastScoredPeriod) {
        acc[period] = sum
          ? team.cumulativePeriodScores[period] || 0
          : team.periodScores[period] || 0
      } else {
        acc[period] = '-'
      }
      return acc
    }, {})

    return {
      ...team,
      periodScores,
    }
  })

  const maxValues = periods.reduce((acc, period) => {
    acc[period] = findUniqueMax(cumulativeScores.map((team) => team.periodScores[period] || 0))
    return acc
  }, {})

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell className={classes.tableHeader}>{translate('ra.text.team')}</TableCell>
            {periods?.map((period, index) => (
              <TableCell key={period} className={classes.tableHeader}>
                {index < 4 ? `${translate('ra.text.quarter')} ${period}` : `OT${index - 3}`}{' '}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {cumulativeScores.map((team) => (
            <TableRow key={team.teamId}>
              <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{team.name}</TableCell>
              {periods.map((period) => {
                const displayedScore = team.periodScores[period]
                return (
                  <TableCell key={`${team.teamId}-${period}`} className={classes.tableCell}>
                    <span
                      className={classes.spanContainer}
                      style={{
                        backgroundColor:
                          displayedScore === maxValues[period] ? '#D3D3D3' : 'transparent',
                      }}
                    >
                      {displayedScore}
                    </span>
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

PerQuarterTable.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      teamId: PropTypes.string,
      name: PropTypes.string,
      periodScores: PropTypes.shape({
        1: PropTypes.number,
        2: PropTypes.number,
        3: PropTypes.number,
        4: PropTypes.number,
        5: PropTypes.number,
        6: PropTypes.number,
        7: PropTypes.number,
        8: PropTypes.number,
        9: PropTypes.number,
      }),
      cumulativePeriodScores: PropTypes.shape({
        1: PropTypes.number,
        2: PropTypes.number,
        3: PropTypes.number,
        4: PropTypes.number,
        5: PropTypes.number,
        6: PropTypes.number,
        7: PropTypes.number,
        8: PropTypes.number,
        9: PropTypes.number,
      }),
      score: PropTypes.number,
      teamPlayerStats: PropTypes.arrayOf(
        PropTypes.shape({
          playerId: PropTypes.string,
          fullName: PropTypes.string,
          opponentName: PropTypes.string,
          gamesPlayed: PropTypes.number,
          gamesStarted: PropTypes.number,
          minutes: PropTypes.string,
          gameStats: PropTypes.shape({
            points: PropTypes.number,
            avgPoints: PropTypes.number,
            twoPointer: PropTypes.number,
            avgTwoPoints: PropTypes.number,
            totalTwoPointsAttempts: PropTypes.number,
            threePointer: PropTypes.number,
            avgThreePoints: PropTypes.number,
            totalThreePointsAttempts: PropTypes.number,
            freeThrows: PropTypes.number,
            avgFreeThrows: PropTypes.number,
            totalFreeThrowsAttempts: PropTypes.number,
            offensiveRebounds: PropTypes.number,
            avgOffensiveRebounds: PropTypes.number,
            defensiveRebounds: PropTypes.number,
            avgDefensiveRebounds: PropTypes.number,
            totalRebounds: PropTypes.number,
            avgRebounds: PropTypes.number,
            assists: PropTypes.number,
            avgAssists: PropTypes.number,
            steals: PropTypes.number,
            avgSteals: PropTypes.number,
            turnOvers: PropTypes.number,
            avgTurnOvers: PropTypes.number,
            blocksAgainst: PropTypes.number,
            avgBlocksAgainst: PropTypes.number,
            foulsCommited: PropTypes.number,
            avgFoulsCommited: PropTypes.number,
            foulsDrawn: PropTypes.number,
            avgFoulsDrawn: PropTypes.number,
            pir: PropTypes.number,
            avgPIR: PropTypes.number,
          }),
        }),
      ),
    }),
  ),
  currentPeriod: PropTypes.number,
  sum: PropTypes.bool,
}

PerQuarterTable.defaultProps = {
  sum: false,
}

export default PerQuarterTable
