import COLORS from '../../utils/constants/colors'

const gameStatsInfoStyles = () => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardMedia: {
    width: '120px',
    height: '120px',
  },
  scoreBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  liveBanner: {
    display: 'flex',
    justifyContent: 'center',
    gap: 4,
    alignItems: 'center',
  },
  notes: {
    fontStyle: 'italic',
    color: COLORS.gray,
  },
})

export default gameStatsInfoStyles
