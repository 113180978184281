import React from 'react'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import SportsHandballIcon from '@material-ui/icons/SportsHandball'
import Group from '@material-ui/icons/Group'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import SettingsIcon from '@material-ui/icons/Settings'
import ContactsIcon from '@material-ui/icons/Contacts'
import SecurityIcon from '@material-ui/icons/Security'
import BarChart from '@material-ui/icons/BarChart'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import TuneIcon from '@material-ui/icons/Tune'
import AppsIcon from '@material-ui/icons/Apps'
import PaymentIcon from '@material-ui/icons/Payment'
import TeamSettingsIcon from '../../assets/icons/teamSettingsIcon'
import COLORS from './colors'
import MyCourtIcon from '../../assets/icons/MyCourtIcon'
import { USER_SCOPES } from './userScopes'
import USER_ORG_ROLES from './userOrgRoles'

const { ALL, COACH, LIVESTREAM, STATS, SOCIAL } = USER_SCOPES
const { OWNER } = USER_ORG_ROLES

const SIDEBAR_OPTION = {
  TEAM_ITEMS: [
    {
      name: 'teamCalendar',
      text: "Team' s Calendar",
      icon: <CalendarIcon />,
      iconSelected: <CalendarIcon style={{ fill: COLORS.orange }} />,
      allowedScopes: ALL,
      allowedUserOrgRoles: ALL,
      payLocked: true,
    },
    {
      name: 'players',
      text: 'Roster Management',
      icon: <Group />,
      iconSelected: <Group style={{ fill: COLORS.orange }} />,
      allowedScopes: [COACH, STATS],
      allowedUserOrgRoles: ALL,
    },
    {
      name: 'practice',
      text: 'Practices',
      icon: <SportsHandballIcon />,
      iconSelected: <SportsHandballIcon style={{ fill: COLORS.orange }} />,
      allowedScopes: [COACH],
      allowedUserOrgRoles: ALL,
    },
    {
      name: 'playbook',
      text: 'Playbook',
      icon: <MenuBookIcon />,
      iconSelected: <MenuBookIcon style={{ fill: COLORS.orange }} />,
      allowedScopes: [COACH],
      allowedUserOrgRoles: ALL,
    },
    {
      name: 'stats',
      text: 'Statistics',
      icon: <BarChart />,
      iconSelected: <BarChart style={{ fill: COLORS.orange }} />,
      allowedScopes: [STATS],
      allowedUserOrgRoles: ALL,
      payLocked: true,
    },
  ],
  MENU_ITEMS: [
    {
      name: 'calendar',
      text: 'Calendar',
      icon: <CalendarIcon />,
      iconSelected: <CalendarIcon style={{ fill: COLORS.orange }} />,
      allowedScopes: ALL,
      allowedUserOrgRoles: ALL,
      payLocked: true,
    },
    {
      name: 'players',
      text: 'Player Management',
      icon: <Group />,
      iconSelected: <Group style={{ fill: COLORS.orange }} />,
      allowedScopes: [COACH, STATS],
      allowedUserOrgRoles: ALL,
    },
  ],
  orgMenuChoices: [
    {
      name: 'orgSettings',
      text: 'Club Settings',
      iconSelected: <SettingsIcon htmlColor={COLORS.orange} />,
      icon: <SettingsIcon style={{ fill: COLORS.menuChoiceGrey }} />,
      path: '/orgSetting',
      allowedScopes: ALL,
      allowedUserOrgRoles: [OWNER],
    },
    {
      name: 'teamSettings',
      text: 'Team Settings',
      iconSelected: <TeamSettingsIcon style={{ fill: COLORS.orange }} />,
      icon: <TeamSettingsIcon style={{ fill: COLORS.menuChoiceGrey }} />,
      path: '/teamSetting',
      allowedScopes: ALL,
      allowedUserOrgRoles: ALL,
      payLocked: true,
    },
    {
      name: 'courts',
      text: 'Courts',
      iconSelected: <MyCourtIcon color={COLORS.orange} />,
      icon: <MyCourtIcon style={{ fill: COLORS.menuChoiceGrey }} />,
      path: '/courts',
      allowedScopes: ALL,
      allowedUserOrgRoles: ALL,
      payLocked: true,
    },
    {
      name: 'subscription',
      text: 'Subscription',
      iconSelected: <PaymentIcon htmlColor={COLORS.orange} />,
      icon: <PaymentIcon style={{ fill: COLORS.menuChoiceGrey }} />,
      path: '/subscription',
      allowedScopes: ALL,
      allowedUserOrgRoles: [OWNER],
    },
    {
      name: 'orgUsers',
      text: 'Club Users',
      iconSelected: <ContactsIcon htmlColor={COLORS.orange} />,
      icon: <ContactsIcon style={{ fill: COLORS.menuChoiceGrey }} />,
      path: '/orgUsers',
      allowedScopes: ALL,
      allowedUserOrgRoles: [OWNER],
      payLocked: true,
    },
    {
      name: 'apps',
      text: 'Apps',
      iconSelected: <AppsIcon htmlColor={COLORS.orange} />,
      icon: <AppsIcon style={{ fill: COLORS.menuChoiceGrey }} />,
      path: '/apps',
      allowedScopes: [LIVESTREAM, SOCIAL],
      allowedUserOrgRoles: [OWNER],
    },
  ],
  PROFILE_ITEMS: [
    {
      name: 'profile',
      text: 'Profile',
      icon: <PersonOutlineIcon />,
      iconSelected: <PersonOutlineIcon style={{ fill: COLORS.orange }} />,
      allowedScopes: ALL,
      allowedUserOrgRoles: ALL,
    },
    {
      name: 'preferences',
      text: 'Preferences',
      icon: <TuneIcon />,
      iconSelected: <TuneIcon style={{ fill: COLORS.orange }} />,
      allowedScopes: ALL,
      allowedUserOrgRoles: ALL,
    },
    {
      name: 'security',
      text: 'Security',
      icon: <SecurityIcon />,
      iconSelected: <SecurityIcon style={{ fill: COLORS.orange }} />,
      allowedScopes: ALL,
      allowedUserOrgRoles: ALL,
    },
  ],
}

export default SIDEBAR_OPTION
