import localStorageHelper from './localStorageHelper'
import localStorageIds from '../constants/localStorageIds'
import orgIsInSubscriptionState from './orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../constants/subscriptionStates'
import userHasOnlySpecificScopes from './userHasOnlySpecificScopes'
import { USER_SCOPES } from '../constants/userScopes'
import isUserOrgRolesValid from './isUserOrgRolesValid'
import USER_ORG_ROLES from '../constants/userOrgRoles'

// If i have only one team i redirect to team level
// And also based if i want to redirect to a new organisation
const redirectToHomeHelper = (teams, redirect, orgToRedirect) => {
  const { removeLocalStorageValue, setLocalStorageValue } = localStorageHelper(
    localStorageIds.SELECTED_TEAM,
  )

  if (
    orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE]) &&
    userHasOnlySpecificScopes([USER_SCOPES.COACH]) &&
    isUserOrgRolesValid([USER_ORG_ROLES.OWNER])
  ) {
    removeLocalStorageValue()
    return redirect('/subscription', [{ org: orgToRedirect?.id }], ['team'])
  }

  if (teams?.length === 1) {
    setLocalStorageValue(teams[0])
    if (orgToRedirect) {
      redirect('/teamCalendar', [{ org: orgToRedirect?.id }, { team: teams[0].id }])
    } else {
      redirect('/teamCalendar', [{ team: teams[0]?.id }])
    }
  } else {
    removeLocalStorageValue()
    if (orgToRedirect) {
      redirect(
        '/calendar',
        [{ org: orgToRedirect?.id }],
        ['team', 'practicePlayersId', 'eventId', 'playerId'],
      )
    } else {
      redirect('/calendar', [], ['team', 'practicePlayersId', 'eventId', 'playerId'])
    }
  }
}

export default redirectToHomeHelper
