import React from 'react'
import DialogWithCloseIcon from '../../../../components/DialogWithCloseIcon/DialogWithCloseIcon'
import { useTranslate } from 'react-admin'
import { Card, CardHeader, CardContent, makeStyles, Avatar } from '@material-ui/core'
import { useMutation } from 'react-query'
import postMetaAccessToken from '../../../../Apis/social/postMetaAccessToken'
import UseQueryParams from '../../../../hooks/useQueryParams'
import QueryKeys from '../../../../utils/constants/queryKeys'
import getOrg from '../../../../Apis/organisation/getOrg'
import useGetLocalStorage from '../../../../hooks/useGetLocalStorage'
import localStorageIds from '../../../../utils/constants/localStorageIds'
import { setLocalStorageValue } from '../../../../utils/helpers/localStorageHelperMethods'

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center', // Ensure items are centered vertically
    marginBottom: '15px',
    cursor: 'pointer',
    height: '60px',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  avatar: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
  },
  cardHeader: {
    flex: '0 1 auto',
    padding: '10px',
  },
})

const MetaAccountChoice = ({ accounts, setMetaAccounts, invalidateQueries }) => {
  const queryParams = UseQueryParams()
  const classes = useStyles()
  const translate = useTranslate()
  const { org: orgId } = queryParams

  const postAccessTokenMutation = useMutation((data) => postMetaAccessToken(data), {
    onSuccess: () => invalidateQueries(),
  })

  const onCloseDialog = () => setMetaAccounts([])

  const onAccountChose = (accountChose) => {
    postAccessTokenMutation.mutate({ orgId, accessToken: accountChose?.access_token })
    onCloseDialog()
  }

  return (
    <DialogWithCloseIcon
      open={Boolean(accounts.length)}
      onClose={() => onCloseDialog()}
      dialogTitle='Choose an account to connect with!'
      dialogWidth='30%'
    >
      {accounts && accounts.length ? (
        accounts.map((account) => (
          <Card
            key={account.id}
            onClick={() => onAccountChose(account, setMetaAccounts)}
            className={classes.card}
          >
            <CardHeader
              className={classes.cardHeader}
              avatar={<Avatar className={classes.avatar} src={account?.picture || ''} />}
              title={account.name}
              titleTypographyProps={{ variant: 'body1' }}
            />
          </Card>
        ))
      ) : (
        <div>{translate('ra.text.noAccounts')}</div>
      )}
    </DialogWithCloseIcon>
  )
}

export default MetaAccountChoice
