import axios from 'axios'
import generalHeader from '../../utils/constants/generalHeader'

const getAccounts = async (userToken) => {
  const url = `https://graph.facebook.com/v20.0/me/accounts?access_token=${userToken}`

  // Step 1: Fetch the accounts
  const accountsResponse = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  const accountsData = accountsResponse.data.data
  // Step 2: Fetch the profile pictures for each account
  const accountsWithImages = await Promise.all(
    accountsData.map(async (account) => {
      const pictureUrl = `https://graph.facebook.com/v20.0/${account.id}/picture?redirect=false&access_token=${userToken}`

      const pictureResponse = await axios.get(pictureUrl, {
        headers: generalHeader(localStorage.getItem('token')),
      })

      const pictureData = pictureResponse.data

      // Add the picture URL to the account object
      return {
        ...account,
        picture: pictureData.data.url,
      }
    }),
  )

  return accountsWithImages
}

export default getAccounts
