import COLORS from '../../utils/constants/colors'

const gamesListStyles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '16px',
    marginBottom: '16px',
  },
  game: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.hoverGrey,
    },
  },
  gameDate: {
    display: 'inline-block',
    marginRight: '8px',
  },
  notes: {
    fontStyle: 'italic',
    color: COLORS.gray,
  },
  finalScoreBtn: {
    backgroundColor: COLORS.orange,
    color: COLORS.white,
    padding: '8px 16px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
  },
  liveBanner: {
    fontStyle: 'italic',
    color: COLORS.gray,
    fontSize: '14px',
    animation: 'pulse 1.5s infinite',
  },
})

const injectKeyframes = () => {
  const styleSheet = document.styleSheets[0] || document.createElement('style')
  if (!document.styleSheets[0]) {
    document.head.appendChild(styleSheet)
  }

  const keyframes = `
      @keyframes pulse {
        0%, 100% {
          opacity: 1;
          transform: scale(1);
        }
        50% {
          opacity: 0.7;
          transform: scale(1.05);
        }
      }
    `

  styleSheet.insertRule(keyframes, styleSheet.cssRules.length)
}

injectKeyframes()

export default gamesListStyles
