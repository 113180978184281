const GAME_EVENT_FORM_BROADCAST_FIELDS = {
  IS_BROADCAST: 'isBroadcast',
  IS_RECORD: 'isRecord',
  IS_COMMENTARY_ON: 'isCommentaryOn',
  TYPE: 'type',
  YOUTUBE_STREAM_KEY: 'youtubeStreamKey',
  IS_ADDITIONAL_YOUTUBE_STREAM_KEY_ADDED: 'isAdditionalYoutubeStreamKeyAdded',
  YOUTUBE_STREAM_KEY_2: 'youtubeStreamKey2',
  EVENT_YT_CONFIG: 'eventYTConfig',
  YOUTUBE_THUMBNAIL: 'thumbnail',
  BROADCAST_TITLE: 'broadcastTitle',
  YOUTUBE_VISIBILITY: 'visibility',
  TEAM_HOME_NAME: 'homeTeamName',
  TEAM_AWAY_NAME: 'awayTeamName',
  TEAM_HOME_LOGO: 'homeTeamLogoPreview',
  TEAM_AWAY_LOGO: 'awayTeamLogoPreview',
  COURT_COMPETITION_NAME: 'competitionName',
  COURT_COMPETITION_LOGO: 'competitionLogoPreview',
  COURT_TOP_LEFT_LOGO: 'courtTopLeftLogoPreview',
  COURT_TOP_RIGHT_LOGO: 'courtTopRightLogoPreview',
  COURT_BOTTOM_LEFT_LOGO: 'courtBottomLeftLogoPreview',
  COURT_BOTTOM_RIGHT_LOGO: 'courtBottomRightLogoPreview',
  COURT_CENTER_LOGO: 'courtCenterLogoPreview',
  LOAD_RECENT_SETUPS: 'loadRecentSetups',
  IS_LIVESTREAM_TYPE_FULL: 'isLivestreamTypeFull',
  UPLOADED_MEDIAS: 'uploadedMedias',
  IS_GAME_ANNOUNCEMENT_ENABLED: 'isGameAnnouncementEnabled',
  GAME_ANNOUNCEMENT_N_HOURS_BEFORE: 'gameAnnouncementNHoursBefore',
  GAME_ANNOUNCEMENT_N_HOURS_BEFORE_HOURS: 'gameAnnouncementNHoursBeforeHours',
  GAME_ANNOUNCEMENT_N_HOURS_BEFORE_MINS: 'gameAnnouncementNHoursBeforeMins',
}

export default GAME_EVENT_FORM_BROADCAST_FIELDS
