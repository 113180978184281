import COLORS from '../../utils/constants/colors'

const gameScoreStyles = () => ({
  gameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    padding: '5px 10px',
    margin: '10px 0',
    borderRadius: '8px',
  },
  teamContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  teamName: {
    margin: '0 10px',
  },
  teamLogo: {
    width: '40px',
    height: '40px',
  },
  score: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 10px',
    borderRadius: '8px',
    fontWeight: 'bold',
    border: '2px solid #E0726E',
  },
  highScore: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#E0726E',
    border: '2px solid #E0726E',
    padding: '2px 10px',
    borderRadius: '8px',
    fontWeight: 'bold',
    color: COLORS.white,
  },
  scoreNumber: {
    margin: '0 5px',
  },
})

export default gameScoreStyles
