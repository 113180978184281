const perQuarterTableStyles = () => ({
  tableHeader: {
    backgroundColor: '#E0726E',
    fontSize: '14px',
    textAlign: 'center',
    paddingTop: '12px!important',
    fontWeight: 'normal!important',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal!important',
    fontSize: '14px',
    textAlign: 'center',
  },
  spanContainer: {
    display: 'inline-block',
    padding: '5px 10px',
    borderRadius: '5px',
  },
})

export default perQuarterTableStyles
