import COLORS from '../../utils/constants/colors'

const playerPerGameTableStyles = () => ({
  table: {
    '& .MuiTableCell-root': {
      padding: '2px 5px',
    },
    '& .MuiTableRow-root': {
      height: '50px',
    },
    tableLayout: 'fixed',
    width: '100%',
    minWidth: '1000px',
  },
  tableHeader: {
    backgroundColor: COLORS.orange,
    fontWeight: 'normal!important',
  },
  playerCell: {
    paddingTop: '12px',
    width: '145px',
    backgroundColor: COLORS.orange,
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  tableCellBorder: {
    borderLeft: '1px solid black',
    textAlign: 'center',
    paddingTop: '12px!important',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal!important',
    fontSize: '14px',
    textAlign: 'center',
  },
  hoverRow: {
    transition: 'background-color 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.hoverGrey,
    },
  },
  ellipsisDiv: {
    width: 140,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  span: {
    textAlign: 'center',
  },
  div: {
    display: 'flex',
    justifyContent: 'space-around',
    fontWeight: 'normal',
  },
  spanDiv: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'normal',
  },
  containerDiv: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'normal',
  },
  minutesDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'normal',
  },
  spaceRight: {
    paddingRight: '22%',
  },
  spaceRightSmaller: {
    paddingRight: '20%',
  },
})

export default playerPerGameTableStyles
