import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import COLORS from '../../utils/constants/colors'
import ShareIcon from '@material-ui/icons/Share'

const MySocialPostOnIcon = (props) => {
  const useStyles = makeStyles({
    addIcon: {
      width: 22,
      height: 22,
      fill: COLORS.grey,
    },
  })
  const classes = useStyles()

  return <ShareIcon {...props} classes={{ root: classes.addIcon }} />
}

export default MySocialPostOnIcon
