const playerStatsTableStyles = () => ({
  typo: {
    fontSize: '14px',
    textAlign: 'center!important',
  },
  boldTypo: {
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'center!important',
  },
  marginTypo: {
    marginTop: '20px',
  },
  tableCell: {
    fontWeight: 'normal!important',
    fontSize: '14px',
    textAlign: 'center!important',
  },
  tableHeader: {
    backgroundColor: '#E0726E',
    textAlign: 'center!important',
  },
})

export default playerStatsTableStyles
