import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography, CardMedia, Grid } from '@material-ui/core'
import gameScoreStyles from './gameScore.styles'
import DefaultOrgIcon from '../../assets/icons/DefaultOrgIcon'

const GameScore = ({ firstTeam, secondTeam }) => {
  const useStyles = makeStyles(gameScoreStyles)
  const classes = useStyles()
  const isFirstTeamHigher = firstTeam.teamScore > secondTeam.teamScore
  const isSecondTeamHigher = secondTeam.teamScore > firstTeam.teamScore

  return (
    <div className={classes.gameContainer}>
      <>
        <Grid item sm={6} className={classes.teamContainer} style={{ justifyContent: 'flex-end' }}>
          {firstTeam?.teamLogo ? (
            <CardMedia className={classes.teamLogo} image={firstTeam?.teamLogo} />
          ) : (
            <DefaultOrgIcon className={classes.teamLogo} />
          )}
          <Typography noWrap variant='subtitle1' className={classes.teamName}>
            {firstTeam.teamName}
          </Typography>
          <div className={isFirstTeamHigher ? classes.highScore : classes.score}>
            <Typography variant='h6' className={classes.scoreNumber}>
              {firstTeam.teamScore}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          sm={6}
          className={classes.teamContainer}
          style={{ justifyContent: 'flex-start' }}
        >
          <div className={isSecondTeamHigher ? classes.highScore : classes.score}>
            <Typography variant='h6' className={classes.scoreNumber}>
              {secondTeam.teamScore}
            </Typography>
          </div>
          <Typography noWrap variant='subtitle1' className={classes.teamName}>
            {secondTeam.teamName}
          </Typography>
          {secondTeam?.teamLogo ? (
            <CardMedia className={classes.teamLogo} image={secondTeam.teamLogo} />
          ) : (
            <DefaultOrgIcon className={classes.teamLogo} />
          )}
        </Grid>
      </>
    </div>
  )
}

GameScore.propTypes = {
  firstTeam: PropTypes.shape({
    teamId: PropTypes.string.isRequired,
    teamLogo: PropTypes.string,
    teamName: PropTypes.string.isRequired,
    teamScore: PropTypes.number.isRequired,
  }).isRequired,
  secondTeam: PropTypes.shape({
    teamId: PropTypes.string.isRequired,
    teamLogo: PropTypes.string,
    teamName: PropTypes.string.isRequired,
    teamScore: PropTypes.number.isRequired,
  }).isRequired,
}

export default GameScore
