import COLORS from '../../../utils/constants/colors'

const metaConfigurationStyles = {
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 20,
  },
  fieldSpacing: {
    marginTop: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  longFieldSpacing: { marginTop: 30 },
  dividerSpacing: {
    marginBottom: 10,
    marginTop: 10,
  },
  longDividerSpacing: {
    marginTop: 30,
  },
  radioContainer: {
    marginLeft: 0,
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 20,
  },
  toggle: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: COLORS.orange,
      '&:hover': {
        backgroundColor: `rgba(${COLORS.orange.r},${COLORS.orange.g},${COLORS.orange.b}, 0.5)`,
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: COLORS.orange,
    },
    '& .Mui-disabled': {
      opacity: 0.5,
    },
  },
}

export default metaConfigurationStyles
